import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { isOnMobileBrowser } from '../Helper';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import './FileDropzone.css';

const baseStyle = {
    display: 'flex',
    //flexDirection: 'column',
    alignItems: 'center',
    margin: '1em',
    padding: '20px',
    height: '200px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#a7a7a7',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#a7a7a7',
    transition: 'border .3s ease-in-out',

};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#000000'
};

const rejectStyle = {
    borderColor: '#000000'
};

const fileNameValidator = (file: File) => {
    const fileNameMatch = file.name.match(
        /^[a-z0-9ùûüÿäàâæçéèêëïîôœ()\-_ .,{}[\]\u0300\u0301\u0302\u0308\u0327]+$/iu
    );
    if (fileNameMatch === null) {
        return {
            code: "file-name-invalid",
            message: "File name is has invalid characters"
        };
    } else if (file.name.indexOf('..') >= 0) {
        return {
            code: "file-name-invalid",
            message: "File name is has invalid characters"
        };
    } else {
        return null;
    }
}

const FileDropzone: React.FC<{
    buttonText: string,
    text: string,
    ondrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
    onfileDialogOpen: () => void;
    onfileDialogCancel: () => void;
}> = (props) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        open,
        isDragReject
    } = useDropzone({
        onDrop: props.ondrop,
        onFileDialogOpen: props.onfileDialogOpen,
        onFileDialogCancel: props.onfileDialogCancel,
        accept: '.docx, .jpeg, .jpg, .tiff, .tif, .pdf, .png, .heic, .heif',
        maxSize: 10485760,
        validator: fileNameValidator
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const {autoComplete, ...inputProps} = getInputProps();
    return (
        <React.Fragment>
            <Grid item xs={12} className="row">
                <br role="presentation"/>
                <button className="btn-wsib" type="button"
                    onClick={open}>{props.buttonText}</button>
            </Grid>

            <Grid item xs={12} className={`row ${isOnMobileBrowser() ? "hidedropzone" : ""}`}>
                <div {...getRootProps({ style, role: 'presentation' })}>
                    <input {...inputProps} />
                    <div style={{ 'margin': 'auto', textAlign: 'center', color: '#5E6A71' }}>
                        <CloudUploadOutlinedIcon></CloudUploadOutlinedIcon>
                        <div>{props.text}</div>
                    </div>
                </div>
            </Grid>
        </React.Fragment>
    );
}

export default FileDropzone;